import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-center lh-20" }
const _hoisted_2 = { class: "mr-4" }
const _hoisted_3 = { class: "mr-2" }
const _hoisted_4 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-country": _withCtx((props) => [
      _createVNode(_component_tm_country, {
        country: props.row.country,
        size: "xSmall"
      }, null, 8, ["country"])
    ]),
    "body-cell-ip": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(props.row.ip), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_tm_icon, {
            class: "light--text",
            name: props.row.browser.icon
          }, null, 8, ["name"]),
          _createVNode(_component_tm_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.browser.label), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_tm_icon, {
            class: "light--text",
            name: props.row.device.icon
          }, null, 8, ["name"]),
          _createVNode(_component_tm_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.device.label), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        (props.row.status)
          ? (_openBlock(), _createBlock(_component_tm_status, {
              key: 0,
              name: "active",
              color: "green",
              outline: ""
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}