import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_accounts_logins_table = _resolveComponent("accounts-logins-table")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "accountSettingsLogins",
    "modal-title": "Last login sessions",
    "no-footer": "",
    size: "xLarge"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_accounts_logins_table, {
        headers: _ctx.tableHeaders,
        items: _ctx.tableItems,
        "hide-actions": ""
      }, null, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}