
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { TableItemType } from '@/definitions/account/profile/types'
import { formatDate } from '@/services/dateTimeService'
import { getTableData } from '@/services/tableService'
import AccountsLoginsTable from '@/components/modals/account/settings/logins/AccountsLoginsTable.vue'

export default defineComponent({
  components: {
    AccountsLoginsTable,
    TmModal,
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Country', value: 'country' },
      { text: 'IP', value: 'ip' },
      {
        text: 'Login date',
        value: 'dateCreated',
        format: (val: string) => formatDate(val),
      },
    ])
    const tableItems = ref<TableItemType[]>(getTableData('loginHistory'))

    return {
      tableItems,
      tableHeaders,
    }
  },
})
