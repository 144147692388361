
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmCountry,
    TmStatus,
    TmTooltip,
    TmTable,
  },
})
